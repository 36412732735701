import React, { useState } from "react"
import { graphql, Link } from 'gatsby'

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"

import useScript from "../service/useScript"

const CareHome = ({ data: { allNodeTemplatePages: { edges } } }) => {
  
  useScript("/assets/js/care-home.js")

  const [activeTab, setActiveTab] = useState(1);

  const content = edges[0];

  const metaTag = []
  const title = typeof (content?.node?.title) !== "undefined" && content?.node?.title.length > 0 ? content?.node?.title : "";

  const pageDetails = Array.isArray(content?.node?.relationships?.components) && content?.node?.relationships?.components.length > 0 ? content?.node?.relationships?.components : []

  // banner Section
  const bannerSection = pageDetails.find(el => el.name === "care_home_banner_section");
  const bannerData = typeof (bannerSection?.relationships?.components) !== "undefined" && Array.isArray(bannerSection?.relationships?.components) && bannerSection?.relationships?.components.length > 0 ? bannerSection?.relationships?.components[0] : []
  const bannerImageAlt = typeof (bannerData?.image?.alt) !== "undefined" && bannerData?.image?.alt.length > 0 ? bannerData?.image?.alt : "Hinduja Hospital"
  const bannerImage = bannerData?.relationships?.image?.uri?.url;
  const mobileBannerImage = bannerData?.relationships?.mobileBannerImage?.uri?.url || bannerImage;
  const bannerTitle = bannerData?.title || "";
  const bannerSubtitle = bannerData?.subtitle || "";

  // tabs Section
  const tabsSection = pageDetails.find(el => el.name === "Tabular Format");
  const tabs = typeof (tabsSection?.relationships?.components) !== "undefined" && Array.isArray(tabsSection?.relationships?.components) ? tabsSection?.relationships?.components : []

  //icon section
  const endSection = pageDetails.find(el => el.name === "two_column");
  const endSectionData = typeof (endSection?.relationships?.components) !== "undefined" && Array.isArray(endSection?.relationships?.components) && endSection?.relationships?.components.length > 0 ? endSection?.relationships?.components[0] : []
  let column1 = Object.keys(endSectionData?.relationships).length > 0 && endSectionData?.relationships?.field_column_1;
  const column2 = Object.keys(endSectionData?.relationships).length > 0 && endSectionData?.relationships?.field_column_2;


  //removing internal: from send requset button
  let field_contact = column1?.field_link?.uri?.replace("internal:", "");





  return (
    <>
      <Layout>
        <Meta
          files={{
            js: [],
            css: [
              "/assets/css/listing.css",
              "/assets/css/about-through-the-ages.css",
              "/assets/css/services.css",
              "/assets/css/accessibility.css",
            ],
          }}
          tags={metaTag}
        />
        <main className="innerpage pt-first-section">
          <section class="inner_common_banner">
              <picture className="banner_img">
                <img src={mobileBannerImage} alt={bannerImageAlt} className="d-md-none"/>
                <img src={bannerImage} alt={bannerImageAlt} className="d-md-block d-none"/>
              </picture>
              <div className="banner_content text_left text_dark">
                  <div className="container">
                      <h1>{bannerTitle.length > 0 ? bannerTitle : "Care@Home"}</h1>
                      <p className="subhead" dangerouslySetInnerHTML={{ __html: bannerSubtitle.length > 0 ? bannerSubtitle : "" }} />
                  </div>
              </div>
          </section>
          
          <section className="section_bg pt-first-section about-ages section-py">
            <div className="container aboutContainer">
              <ul className="nav nav-tabs gallery-tabs min-space" role="tablist">
                {
                  typeof (tabs) !== "undefined" && tabs.map((el, index) => {
                    return (
                      <li role="presentation" className="nav-item" key={index}>
                        <a
                          onClick={() => setActiveTab(index + 1)}
                          className={`nav-link ${activeTab === index + 1 ? 'active' : ''}`}
                          href={`#${el?.field_title}`}
                          data-toggle="tab"
                          role="tab"
                          aria-controls={`${el?.field_title}`}
                          aria-selected="true"
                        >
                          {el?.field_title}
                        </a>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
            <div className="container">
              <div className="tab-content mt-3">
                {
                  typeof (tabs) !== "undefined" && tabs.map((el, index) => {

                    const data = typeof (el?.text?.processed) !== "undefined" && el?.text?.processed.length > 0 ? el?.text?.processed : "";

                    return (
                      <div
                        key={index}
                        className={`tab-pane ${activeTab === index + 1 ? 'active' : ''}`}
                        id={el?.field_title}
                        role="tabpanel"
                      >
                        <div className="card">
                          <div className="row">
                            <div className="col-md-12">
                              <div dangerouslySetInnerHTML={{ __html: data }} />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </section>
          <section className="services_section common_services pb-section">
            <div className="container">
              <div className="row">
                <div className="col-md-6 ">
                  <div className="service_card">
                    <figure>
                      <img src={column1?.relationships?.field_icon?.uri?.url} alt="send a request" style={{ margin: "0 auto", display: "block" }} />
                    </figure>
                    <h3>{column1?.field_title !== undefined && column1?.field_title.length > 0 ? column1?.field_title : "Send A Request"}</h3>
                    <div className="btn_wrap">
                      <Link to={column1?.field_link?.uri ? field_contact : "/"} className="btn btn-primary">
                        {typeof (column1?.field_contact) !== "undefined" ? column1?.field_contact : "Click Here"}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="service_card">
                    <figure>
                      <img src={column2?.relationships?.field_icon?.uri?.url} alt="Call For Registration<" />
                    </figure>
                    <h3>{column2?.field_title ? column2?.field_title : "Call For Registration"}</h3>
                    <p dangerouslySetInnerHTML={{ __html: typeof (column2?.field_contact) !== "undefined" && column2?.field_contact }} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    </>
  )
}


export const query = graphql`
query careAtHome {

  allNodeTemplatePages(filter: {path: {alias: {regex: "/care-home$/"}}}) {

    edges {

      node {

        id

        title

        path {

          alias

        }

        metatag {
          attributes {
              content
              href
              name
              property
              rel
          }
        }

        relationships {

          components: field_component_type {

            __typename

            ...ParagraphBannercare

            ...ParagraphSectioncare

          }

        }

      }

    }

  }

}

 

fragment ParagraphSectioncare on paragraph__section {

  id

  name: field_name

  relationships {

    components: field_components {

      __typename

      ...ParagraphBannercare

      ...ParagraphTitleAndDescriptioncare

      ... on paragraph__2_col {

        id

        relationships {

          field_column_1 {

            ... on paragraph__emergency_info {

              id

              field_title

              field_contact

              field_link {

                uri

                title

              }

              relationships {

                field_icon {

                  uri {

                    value

                    url

                  }

                }

              }

            }

          }

          field_column_2 {

            field_contact

            field_link {

              title

              uri

            }

            field_title

            relationships {

              field_icon {

                uri {

                  value

                  url

                }

              }

            }

          }

        }

      }

    }

  }

}

 

fragment ParagraphTitleAndDescriptioncare on paragraph__title_and_description {

  id

  field_title

  text: field_description {

    processed

  }

  field_cta_link {

    title

    uri

  }

}

 

fragment ParagraphBannercare on paragraph__banner {

  id

  title: field_title

  subtitle: field_subtitle

  image: field_banner_image {

    alt

  }

  mobileBannerImage: field_mobile_banner {

    alt

  }

  bannerLink: field_banner_link {

    uri

    title

  }

  relationships {

    image: field_banner_image {

      id

      uri {

        value

        url

      }

    }

    mobileBannerImage: field_mobile_banner {

      id

      uri {

        url

        value

      }

    }

  }

}
`


export default CareHome